<template>
  <div class="control-strip">
    <div class="left">
    <el-checkbox v-if="imagesList.length"  v-model="checked" :indeterminate="isIndeterminate"></el-checkbox>
      <div style="width: 14px;height: 14px;" v-else></div>

<!--      //控制图册-->
      <div class=" switch_iocn" v-if="!returnShow">
        <i class=" iconfont icon-liebiao" :class="{'active':activeIndex === 0}" @click="switchTables(0)"></i>
        <i class="icon-kapian iconfont" :class="{'active':activeIndex === 1}" @click="switchTables(1)"></i>
      </div>
<!--      控制图片-->
      <div class=" switch_iocn" v-else>
        <i class=" iconfont icon-liebiao" :class="{'active':active === 0}" @click="switchActive(0)"></i>
        <i class="icon-kapian iconfont" :class="{'active':active === 1}" @click="switchActive(1)"></i>
      </div>
      <div v-if="returnShow" class="text_x" @click="returnUpperLevel">返回上一级</div>
      <div v-if="!!checkListID.length" class="flex">
        <div class="text_x" @click="batchDownload">批量下载</div>
        <div class="text_x" @click="batchClassification">分类</div>
        <div class="text_x" @click="deleteImg">删除</div>
      </div>
    </div>
    <div class="right" v-if="returnShow">
        <div class="search" >
        <el-input
          size="mini"
          placeholder="名称"
          v-model="inputSearch"
          class="search-inp"
          @keyup.enter.native="searchBtn"
          clearable
          >
        </el-input>
        <el-button icon="el-icon-search" size="mini" class="search-btn" @click.enter="searchBtn"></el-button>
        </div>
    </div>
  </div>
    
</template>

<script>


import JSZip from 'jszip'
import axios from 'axios'
import FileSaver from 'file-saver'
export default {

 name:'my-contrul-strip',
 props:{
   checkListID:{
     type: Array,
     default:()=>[]
   },
   imagesList:{
     type:Array,
     default:()=>([])
   },
   returnShow:{
     type:Boolean,
     default:false
   }
 },
  data () {
    return {
        checked: false,
        inputSearch:null ,//搜索的内容
        isIndeterminate:false,
        activeIndex:0,
        active:1,
        list:[],

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    getFile(url){
      return new Promise((resolve, reject) => {
        let obj = {
          method: 'get',
          url,
          responseType: 'blob'
          // responseType:'arraybuffer'
        }
        axios(obj)
            .then(data => {
              resolve(data.data)
            })
            .catch(error => {
              reject(error.toString())
            })
      })
    },
   //批量下载
    async batchDownload(){
      this.list = []
      if(Array.isArray(this.imagesList) && Array.isArray(this.checkListID)) {
       this.imagesList.forEach(val=>{
          return  this.checkListID.forEach(item=>{
            if(val.id === item) {
              this.list.push(val)
            }
          })
       })

        const zip = new JSZip()
        const cache = {}
        const promises = []
        this.list.forEach(item => {
          console.log(item)
          const promise = this.getFile(item.larger.img).then(data => {
            // const arr_name = item.larger.img.split('/') // 下载文件, 并存成ArrayBuffer对象
            // const file_name = arr_name[arr_name.length - 1] // 获取文件名
            const file_name = item.name // 获取文件名
            zip.file(file_name, data, { binary: true }) // 逐个添加文件
            cache[file_name] = data
          })
          promises.push(promise)
        })
        Promise.all(promises).then(() => {
          zip.generateAsync({ type: 'blob' }).then(content => {
            // 生成二进制流
            FileSaver.saveAs(content, '文件下载.zip') // 利用file-saver保存文件  自定义文件名
            this.$emit('closeCheck')
          })
        })

      }


    },

    returnUpperLevel(){
      this.inputSearch = null
      this.$emit('returnUpperLevel',false)
    },
    //控制图片
    switchActive(e){
      this.active = e
      this.$emit('switchActive',e)
    },
    // 控制图册
    switchTables(e){
      this.activeIndex = e
      this.$emit('switchTables',e)
    },
    // 批量删除
    deleteImg(){
      this.$confirm('此操作将永久删除该文件, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        this.$emit('remover',this.checkListID)
      })
    },

    //批量分类
    batchClassification(){
      // 分类
      this.$emit('classifyBatch')
    },

    //搜索
    searchBtn(){
      // console.log(this.inputSearch);
      this.$emit('searchBtn',this.inputSearch)
    },
    // 全选
    updateChecked(){
      this.checked = true
      this.isIndeterminate = false
    },
    // 选中一个或多个且未选择全部
    updateCheckedFlase() {
      this.isIndeterminate = true
    },
    isIndeterminateFlase(){
       this.checked = false
      this.isIndeterminate = false
    }
  },
  watch:{
    //全选
    checked(){
      if(this.checked){
        this.isIndeterminate = false
        this.$emit('checkedTrue')
      } else {
        this.isIndeterminate = false
        this.$emit('checkedFlase')
      }
    },

  }
}
</script>

<style scoped lang="less">
.flex {
  display: flex;
}
.control-strip{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  .left {
    display: flex;
    line-height: 28px;
    cursor: pointer;
    .text_x {
      font-size: 14px;
      font-weight: 400;
      color: #FA4A14;
      line-height: 28px;
      margin-left: 30px;
    }
    .switch_iocn {
      display: flex;
      margin-left: 30px;
      width: 50px;
      height: 27px;
      border: 1px solid #eee;

      cursor: pointer;
      text-align: center;
      .icon-liebiao {
        width: 25px;
        line-height: 28px;

      }
      .icon-kapian {
        width: 25px;
        line-height: 28px;

      }
      .active {
        background: #999999;
        color: #fff;
      }
    }
  }

    .text{
      color: #999999;
      margin-left: 20px;
    }
    margin: 20px 0;
    .batch{
        width: 100px;
        height: 26px;
        margin: 0 20px;
    }
    .show{
        width: 60px;
        height: 26px;
        margin: 0 9px;
    }
    .right{
        width: 412px;
        height: 26px;
        border: 1px solid #999999;
        .search{
           display: flex;
           justify-content: space-between;
           .search-inp{
               width: 370px;
               height: 24px;
               /deep/input{
                   border: 0;
                   height: 100%;
               }
           }
            .search-btn{
                font-size: 14px;
                height: 24px;
                border: 0;
                color: #000;
            }
        }
    }
}
/deep/.el-input__inner {
  padding-right: 5px;
  padding-left: 10px;
}
</style>
