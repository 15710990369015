<template>
  <div>
    <el-dialog
        title="更改名称"
        :visible.sync="dialogVisible"
        width="654px"
        :before-close="handleClose">
      <el-form :model="form" label-width="90px" :rules="rules" ref="ruleForm">
        <el-form-item label="更改名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入更改名称"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="confirm">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    updateName: {
      type: String,
      required: true
    },
    imagesList: {
      type: Array,
      default: () => []

    }
  },
  data() {
    let validatePass = (rule, value, callback) => {
      let arr = []
      this.imagesList.forEach(item => {
        arr.push(item.name)
      })
      if (arr.indexOf(value) !== -1) {
        callback(new Error('名称已存在，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: true,
      form: {
        name: ''
      },
      // 效验表单
      rules: {
        name: [
          {required: true, message: '图片名称不能为空!', trigger: 'blur'},
          {validator: validatePass, trigger: 'blur'}
        ]
      }
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false
      this.$emit('close', this.dialogVisible)
    },
    confirm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('upDadeImgName', this.form.name)
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog__header {
  border-bottom: 1px solid #E7E7E7;
}

/deep/ .el-dialog__title {
  font-size: 16px;
  font-weight: 600;
  font-family: PingFang-SC-Medium, PingFang-SC;
  color: #333333;
  line-height: 22px;
}

/deep/ .el-form-item__label {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}

/deep/ .el-button {
  font-size: 16px;
}

/deep/ .el-form-item__error {
  margin: 10px;
}
</style>
