<template>
  <div class="app">
    <!-- 上传按钮  -->
    <my-gallery-top
        @time='time'
        :meta="meta"
        @changeUploadImg='changeUploadImg'
        @updateFormat='updateFormat'
        @toggleClassify='toggleClassify'
        :categoryList="categoryList"
        @update="getPhotolist"
        :imagesList='imagesList'
    />

    <!-- 搜索批量操作 -->
    <my-control-strip
        ref="controLstrip"
        @checkedFlase='checkedFlase'
        @checkedTrue='checkedTrue'
        @classifyBatch='classifyBatch'
        @searchBtn='searchBtn'
        :checkListID='checkListID'
        @remover='remover'
        @switchTables="switchTables"
        :imagesList="imagesList"
        :returnShow="returnShow"
        @returnUpperLevel="returnUpperLevel"
        @switchActive="switchActive"
        @closeCheck="closeCheck"
    />


    <!-- 图库宫格-->
    <my-gallery-columns
        v-if="tablesShow"
        @getCheckListID='getCheckListID'
        @isIndeterminateFlase='isIndeterminateFlase'
        @allCheck='allCheck'
        @updateCheckedFlase='updateCheckedFlase'
        @remover='remover'
        @show="changeShow"
        @classifyIsShow='classifyIsShow'
        @update="getPhotolist"
        @setPictureId="setPictureId"
        @dataLoading="dataLoading"
        ref="gallery"
        :changeNameshow="ChangeNameShow"
        :imagesList='imagesList'
        :checkListID='checkListID'
        :categoryList="categoryList"
        :noMore="noMore"
        :switchActiveShow="switchActiveShow"
    />

    <!--    //图片列表-->

    <gallery-list
        :noMore="noMore"
        @dataLoading="dataLoading"
        :imagesList='imagesList'
        @allCheck='allCheck'
        ref="galleryList"
        @updateCheckedFlase='updateCheckedFlase'
        @isIndeterminateFlase='isIndeterminateFlase'
        @getCheckListID='getCheckListID'
        @show="changeShow"
        @classifyIsShow='classifyIsShow'
        @remover='remover'
        :categoryList="categoryList"
        @update="getPhotolist"
        @setPictureId="setPictureId"
        :switchActiveShow="switchActiveShow"
        v-else
    />

    <!-- 更改名称 -->
    <change-name
        :imagesList='imagesList'
        v-if="ChangeNameShow"
        :updateName='updateName'
        @close="closeShow"
        @upDadeImgName='upDadeImgName'/>

    <!-- 图册 -->
    <classify
        @emptyId='emptyId'
        @updated='updatedImg'
        :checkListID='checkListID'
        :classifyShow='classifyShow'
        @close='classifyClose'
        :categoryList='categoryList'
        :addToAlbumId='addToAlbumId'/>

    <!-- 上传图片 -->
    <upload-img
        :dialogVisible='dialogVisible'
        @hideeUploadImg='hideeUploadImg'
        @updated='updatedImg'
        :categoryList='categoryList'/>

    <!-- 分页 -->
    <!--    <my-gallery-foot-->
    <!--    v-if="imagesListLengthShow"-->
    <!--    :meta='meta'-->
    <!--    @currentPage='currentPage'  />-->
  </div>
</template>

<script>
import {
  getBeginWeek, //本周的开始时间
  getEndWeek,  // 本周的结束时间
  getBeginToday, // 今天的开始时间
  getCurrentDate, //当前时间
  getBeginYesterday, //昨天的开始时间
  getEndYesterday, // 昨天的结束时间
  getBeginMonth, // 本月的第一天
  getEndMonth, //本月的最后一天，
  getBeginLastMonth, //上个月的一天
  getEndLastMonth, //上个月最后一天
  year
} from '@/utils/timejs.js';
import {getImagesList, upDadeImgName, deleteImages, albumPaging, getPhotolist} from '@/api/map.js';
// 更改名称
import ChangeName from '../myGallery/components/changeName.vue'
// 图册
import Classify from '../myGallery/components/classify'
//搜索批量操作
import MyControlStrip from '../myGallery/components/myControlStrip.vue'
//我的图库图片
import MyGalleryColumns from '../myGallery/components/myGalleryColumns.vue'
// 分页
import MyGalleryFoot from '../myGallery/components/MyGalleryFoot.vue'
// 上传图片按钮   头部
import myGalleryTop from '../myGallery/components/myGalleryTop.vue'
// 上传图片
import uploadImg from './components/uploadImg'
import GalleryList from "./components/galleryList";
// 创建图册按钮  ， 批量操作
// import PhotoTop from './components/photoTop'


export default {
  components: {
    GalleryList,
    myGalleryTop,
    MyGalleryColumns, //我的图库图片
    MyControlStrip,
    ChangeName,
    Classify,
    MyGalleryFoot,
    uploadImg,
    // PhotoTop,

  },
  data() {
    return {
      sollShow: false,
      imagesListLengthShow: false, //分页显示状态
      ChangeNameShow: false, //更改名称
      classifyShow: false, //分类弹窗
      dialogVisible: false,
      imagesList: [], //图库列表
      perPage: 30,// 每页
      meta: {}, //分页
      updateId: null, //更改名称id
      page: 1, // 页码
      format: null,//格式
      categoryList: [],//图册
      addToAlbumId: null, //相册ID
      albumPagingPerPage: 20, //图册每页
      albumPagingPage: 1, //图册页码
      Photolist: {}, // 图册列表
      albumId: null, // 图册id
      updateName: '', //更改名称的名字
      checkListID: [], //图片ID
      PhotolistMeta: {}, //图册分页
      startTime: null, //今天开始时间
      endTime: null,//现在的时间
      subAccountCode: null,//子账号code
      nameOrCode: null,
      original: 0,
      tablesShow: false,
      returnShow: false,
      switchActiveShow: true,
    }
  },
  created() {

  },
  async mounted() {
    // 图册
    await this.getPhotolist()
    //分页
    await this.albumPaging()
  },
  methods: {
    closeCheck() {
      this.updateCheckedFlase()
      this.isIndeterminateFlase()
      this.checkedFlase()
    },
    dataLoading() {
      if (!this.sollShow) {
        this.page++
        this.getImagesList()
      } else {
        this.imagesList = []
      }


    },
    //返回上一级
    returnUpperLevel() {

      this.sollShow = true

      if (this.$refs.gallery) {
        this.$refs.gallery.setAtlasShow()
        this.$refs.gallery.checkedFalseList()
      } else {
        this.$refs.galleryList.setAtlasShow()
        this.$refs.galleryList.checkedFalseList()
      }
      this.returnShow = false
      this.page = 1
      this.nameOrCode = null
      this.imagesList = []

    },

    //点击图册 显示图片
    setPictureId(id) {

      this.sollShow = false
      this.albumId = id
      this.page = 1
      this.getImagesList()
      this.returnShow = true

    },
    //控制图片的切换
    switchActive(e) {
      if (!!e) {
        this.switchActiveShow = true
      } else {
        this.switchActiveShow = false
      }
    },
    // 图册切换宫格跟列表
    switchTables(e) {
      // 相册切换
      if (!!e) {
        this.tablesShow = true
      } else {
        this.tablesShow = false
      }
      if (this.$refs.gallery) {
        this.$refs.gallery.checkedFalseList()
      }
      if (this.$refs.galleryList) {
        this.$refs.galleryList.checkedFalseList()
      }
      this.isIndeterminateFlase()
      if (this.original !== e) {
        this.imagesList = []
        this.returnShow = false
        this.original = e
        this.page = 1
      }


    },


    // 更改名称弹窗
    changeShow(event) {
      this.ChangeNameShow = true
      this.updateId = event
    },

    closeShow(val) {
      this.ChangeNameShow = val
    },

    closeNameShow(val) {
      this.ChangeNameShow = val
    },

    //相册弹窗 相册id
    classifyIsShow(event) {
      let album_id
      this.addToAlbumId = event
      this.classifyShow = true
      this.imagesList.forEach(item => {
        //单个
        if (!!this.addToAlbumId) {
          if (item.id === this.addToAlbumId) {
            album_id = item.album_id
          }
        }
        //批量
      })
      this.categoryList = this.categoryList.filter(val => val.id !== album_id)


    },

    changeUploadImg() {
      this.dialogVisible = true
    },

    // 关闭上传图片弹窗
    hideeUploadImg() {
      this.dialogVisible = false
    },

    //搜索
    searchBtn(event) {
      this.page = 1
      this.perPage = 30
      this.nameOrCode = event
      this.getImagesList(true)
    },

    //获取我的图库列表
    async getImagesList(e) {

      try {
        const {data} = await getImagesList({
          is_all: 0,
          perPage: this.perPage,
          keyword: this.nameOrCode, //名称或者编号
          page: this.page,
          format: this.format,
          albumId: this.albumId,
          startTime: this.startTime,
          endTime: this.endTime,
          user_code: this.subAccountCode
        })
        console.log(data)
        if (!this.imagesList.length || e) {
          this.imagesList = data?.data
        } else {
          data?.data.forEach(item => {
            const res = this.imagesList.find(el => el.id === item.id)
            if (!res) {
              item.checkboxShow = false
              this.imagesList.push(item)
            }
          })
        }
        if (this.imagesList.length > 0) {
          this.imagesListLengthShow = true
        }

        this.meta = data.meta
        if (this.sollShow) {
          this.imagesList = []
        }
      } catch (error) {
        this.$message.error(error)

      }
      //  //   开启loading
      //      this.openloading();
      //    //   关闭loading
      //        setTimeout(()=>{
      //            this.loading.close();

      //        },500)
    },

    // 更改名称，名字
    async upDadeImgName(event) {
      this.imagesList.forEach(item => {
        if (item.id === this.updateId) {
          event = event + '.' + item.format
        }
      })
      try {
        const {message} = await upDadeImgName({
          imgId: this.updateId,
          imgName: event
        })

        this.imagesList.forEach(item => {
          if (item.id === this.updateId) {

            this.$set(item, 'name', event)
          }
        })

        await this.getImagesList()
        this.ChangeNameShow = false

        this.$message.success(message)
      } catch (error) {
        this.$message.error(error)
      }
    },

    // 删除图片
    async remover(event) {
      try {
        const {message} = await deleteImages(
            {
              imgId: event
            }
        )
        this.$message.success(message)


        if (Array.isArray(event)) {
          this.imagesList = this.imagesList.filter(item => {
            return event.forEach(val => {
              return val !== item.id
            })
          })

        } else {
          this.imagesList.forEach((item, index) => {
            if (item.id === event) {
              this.imagesList.splice(index, 1)
            }
          })
        }
        await this.getImagesList()
        await this.isIndeterminateFlase()

      } catch (error) {
       this.$message.error(error)

      }
    },

    // 分页
    currentPage(event) {
      this.page = event
      // this.getImagesList()
    },

    // 获取图册
    async getPhotolist() {
      try {
        const {data} = await getPhotolist()

        let arr = [
          {
            name: '未分类',
            id: 0
          }
        ]
        this.categoryList = [...arr, ...data]
      } catch (e) {
        this.$message.error(e)
      }
    },


    //更新列表
    updatedImg() {
      this.page = 1
      // this.imagesList = []
      this.getImagesList()
    },

    // 选择格式
    updateFormat(event) {
      if (event === '全部') {
        this.format = ''
        this.page = 1
        this.perPage = 30
      } else {
        this.format = event
      }

    },

    // 图册
    toggleClassify(event) {
      if (event === '全部') {
        this.albumId = 0
      } else if (event === '未选择') {
        this.albumId = false
      } else {
        this.albumId = event
      }

      this.getImagesList()
    },

    // 获取相册列表
    async albumPaging() {
      try {
        const {data} = await albumPaging({
          perPage: this.albumPagingPerPage,
          page: this.albumPagingPage
        })
        this.Photolist = data
        this.PhotolistMeta = data.meta
      } catch (e) {
        this.$message.error(e)
      }
    },

    // 分类弹窗
    classifyBatch() {
      let album_id
      this.classifyShow = true

      this.imagesList.forEach(item => {
        //单个
        // if(!!this.addToAlbumId) {
        //   console.log('@@@')
        //     // if(item.id === this.addToAlbumId){
        //     //   album_id = item.album_id
        //     // }
        // }
        //批量
        if (!!this.checkListID.length) {
          if (item.id === this.checkListID[0]) {

            album_id = item.album_id
          }
        }
      })


      this.categoryList = this.categoryList.filter(val => val.id !== album_id)

    },

    //
    classifyClose() {
      this.classifyShow = false
      this.getPhotolist()
    },

    // 清空选择的id
    emptyId() {
      if (this.$refs.gallery) {
        this.$refs.gallery.checkEmptyList()
      }
      if (this.$refs.galleryList) {
        this.$refs.galleryList.checkEmptyList()
      }

      // this.addToAlbumId = null

      //单个
      if (!!this.addToAlbumId) {
        this.imagesList = this.imagesList.filter(v => v.id !== this.addToAlbumId)
      }
      //批量

      if (!!this.checkListID.length) {

        this.imagesList = this.imagesList.filter(v => {
          if (!this.checkListID.includes(v.id)) {
            return v
          }
        })
      }
      this.checkListID = []
    },

    // 更改时间
    time(event) {

      if (event === '全部') {
        this.startTime = null
        this.endTime = null
      } else if (event === '今天') {
        this.startTime = Date.parse(getBeginToday()) / 1000
        this.endTime = Date.parse(getCurrentDate()) / 1000

      } else if (event === '昨天') {
        this.startTime = getBeginYesterday() / 1000
        this.endTime = parseInt(getEndYesterday() / 1000)

      } else if (event === '本周') {
        this.startTime = getBeginWeek() / 1000
        this.endTime = parseInt(getEndWeek() / 1000)
      } else if (event === '本月') {
        this.startTime = getBeginMonth() / 1000
        this.endTime = parseInt(getEndMonth() / 1000)
      } else if (event === '上月') {
        this.startTime = getBeginLastMonth() / 1000
        this.endTime = parseInt(getEndLastMonth() / 1000)
      } else if (event === '今年') {

      }
      this.getImagesList()
    },

    // 复选框全选改变批量全选
    allCheck() {
      this.$refs.controLstrip.updateChecked()
    },

    updateCheckedFlase() {
      this.$refs.controLstrip.updateCheckedFlase()
    },

    isIndeterminateFlase() {
      this.$refs.controLstrip.isIndeterminateFlase()
    },

    checkedTrue() {

      if (this.$refs.gallery) {
        this.$refs.gallery.checkedTrueList()
      }
      if (this.$refs.galleryList) {
        this.$refs.galleryList.checkedTrueList()
      }
    },

    checkedFlase() {
      if (this.$refs.gallery) {
        this.$refs.gallery.checkedFalseList()
      }
      if (this.$refs.galleryList) {
        this.$refs.galleryList.checkedFalseList()
      }

    },

    getCheckListID(val) {
      this.checkListID = val
    }
  },

  watch: {

    // 更改名称
    updateId() {
      this.imagesList.forEach((item, index) => {
        if (item.id === this.updateId) {
          this.updateName = this.imagesList[index].name
        }
      })
    },

  },

  computed: {
    noMore() {
      return this.page >= this.meta.total / this.perPage
    },
  }
}
</script>

<style scoped lang="less">

</style>
