<style scoped lang="less">
/deep/ .el-dialog__header {
  padding-bottom: 0;
}

/deep/ .el-dialog__title {
  width: 64px;
  height: 22px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
}

/deep/ .el-dialog__body {
  padding: 10px 0;
  padding-bottom: 30px;
}

/deep/ .el-form-item__content {
  margin-left: 0 !important;
}

/deep/ .el-input__inner {
  width: 590px;
  height: 26px;
}

.uploadBox {
  width: 590px;
  height: 230px;
  border: 1px dashed #CCCCCC;
  margin: 0 32px;
  border-radius: 10px;


}

/deep/ .el-form-item {
  margin-bottom: 10px;
}

.addImg {
  width: 590px;
  height: 230px;
  position: relative;
}

.textIMg {
  width: 390px;
  height: 40px;
  position: absolute;
  top: 250px;
  left: 50%;
  margin-left: -190px;
  font-weight: 400;
  color: #666666;
  line-height: 20px
}

.introduce {
  text-align: center;
}

.clause {
  display: flex;
  width: 590px;
  height: 30px;
  margin: 20px 32px 5px;
}

.clauseText {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 16px;
  margin-left: 10px;
}

/deep/ .el-popper {
  margin-left: 20px !important;
}

/deep/ .el-select-dropdown {
  margin-left: 20px !important;
}

.left30px {
  margin-left: 32px;
}

.classifyImagesBox {
  height: 230px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 0 15px 20px;
  overflow-x: hidden;
}

.classifyImages {
  position: relative;
  margin-right: 20px;
  margin-bottom: 15px;
  width: 92px;
  height: 92px;
  border: 1px solid #eee;

  & > img {
    width: 100%;
    height: 100%;
  }

  &:hover .mask {
    display: block;
  }

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    display: none;
  }

  .delete {
    text-align: center;
    margin-top: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 22px;
    cursor: pointer;
  }
}

.hideemagin {
  margin-right: 0 !important;
}

.btn {
  width: 64px;
  height: 32px;
  background: #E94615;
  border-radius: 2px;
  margin: 25px 0;
  margin-left: 560px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  outline: none;
}

/deep/ .el-upload-dragger, /deep/ .el-upload {
  border: none;
  // width: 588px;
  // height: 227px;
  border-radius: 10px;
}

/deep/ .el-icon-upload {
  margin-top: 50px;
  margin-left: 60%;
}

/deep/ .el-progress {
  // width: 75px;
  position: absolute;
  bottom: 20px;
  left: 15px;
}

/deep/ .el-progress-circle {
  width: 60px !important;
  height: 50px !important;
}

.hide {
  display: none;
}

.border {
  width: 590px;
  border-top: 1px solid #E7E7E7;
  margin-left: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.elUpload {
  width: 92px;
  height: 92px;
  border: 1px dashed #CCCCCC;
  margin-right: 20px;
  margin-bottom: 15px;
}

.el-icon-plus {
  font-size: 40px;
  text-align: center;
  line-height: 90px;
  margin-left: 60%;

}

::-webkit-scrollbar {
  width: 6px;
  height: 70px;
  background-color: #ffff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #D8D8D8;
}

</style>

<template>
  <div>
    <el-dialog
        title="上传图片"
        :visible.sync="dialogVisible"
        width="654px"
        :before-close="handleClose">
      <div class="border"></div>
      <el-form :model="form">
        <el-form-item label-width="30px">
          <div class="left30px">
            <el-select v-model="form.region" placeholder="选择图册">
              <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>
      <!-- 上传图片框框 -->
      <div class="uploadBox">
        <!-- 未上传状态 -->
        <div v-show='!uploadFileList.length'>
          <div class="addImg">

            <el-upload
                ref="upload"
                class="upload-demo"
                drag
                :show-file-list='false'
                action="/"
                multiple
                :before-upload="beforeAvatarUpload"
            >
              <i class="el-icon-upload"></i>

            </el-upload>
          </div>
          <div class="textIMg">
            <div class="introduce">点击或拖拽文件到这里上传</div>
            <div>为了保证图片可以正常使用，仅支持30M内jpg、png格式上传</div>
          </div>
        </div>
        <!-- 上传完成 -->
        <div class="classifyImagesBox " v-show='uploadFileList.length'>

          <div class="classifyImages" v-for="(item,index) in uploadFileList" :key="index">
            <!-- 进度条 -->
            <el-progress v-if="item.error && !item.url" status="exception" type="circle" color="#FA4A14" :width="70"
                         :percentage="(item.progress * 100)"></el-progress>
            <el-progress v-if="!item.error && !item.url" type="circle" color="#FA4A14" :width="70"
                         :percentage="(item.progress * 100)"></el-progress>
            <el-image
                style="width: 100%;height: 100%"
                v-if="!!item.url"
                class="img"
                :src="item.url"
                fit="scale-down"
            ></el-image>
          </div>
          <el-upload
              ref="upload"
              class="elUpload"
              :show-file-list='false'
              action="/"
              multiple
              :before-upload="beforeAvatarUpload"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>

          </el-upload>


        </div>
      </div>
      <div class="clause" v-if="!uploadFileList.length">
        <el-checkbox v-model="checked"></el-checkbox>
        <div class="clauseText">上传图片和文字时不得存在涉政、涉黄、侵权等违法行为，情节严重的将予以封号处理，感谢您的配合。我已经阅读并同意。</div>
      </div>
      <!-- 上传状态 -->

    </el-dialog>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
import OSS from 'ali-oss'
import UUID from 'uuidjs'
// import {uploadFile2AliOSS} from '~/api/designer'
import {verifyImageMd5, saveImage, ossStsConfig} from '@/api/map.js';
import {getImgFormatInfo} from "../../../../api/map";
import {mapState} from "vuex";
//读取配置文件
const baseHttpPrefix = String(process.env.VUE_APP_OSS_BASE_HTTP_PREFIX);
const baseUrl = String(process.env.VUE_APP_OSS_BASEURL)

export default {
  data() {
    return {
      checked: true, //条款
      form: {
        region: null,
      },
      //ali oss client
      ossClient: null,
      imgUrl: null,
      //上传的列表数据 {uuid,progress,url,error,photoAlbumId}
      uploadFileList: [],

    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    // 图册
    categoryList: {
      type: Array,
      required: true
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    //关闭弹窗
    handleClose(done) {
      this.$emit('hideeUploadImg')
      this.uploadFileList = []
      this.form.region = ''
      this.$emit('updated')
    },
    beforeAvatarUpload(file) {
      const {size, type, name} = file
      if (type !== 'image/jpeg' && type !== 'image/jpg' && type !== 'image/png') {
        this.$message.error('仅支持jpeg ｜ jpg ｜ png格式的图片')
        return false
      }

      if (size > 1024 * 1024 * 20) {
        this.$message.error('仅支持小于20M的图片')
        return false
      }

      if (!this.checked) {
        this.$message.error('请勾选条款')
        return false
      }

      this.handleUploadFile(file)
      return false
    },
    async handleUploadFile(file) {

      const {name} = file

      const uuid = UUID.generate()
      this.createImage(uuid)

      try {
        //校验md5
        const base64 = await this.fileByBase64(file)
        const imageMd5 = CryptoJS.MD5(base64).toString()
        //校验是否上传过图片

        const {data} = await verifyImageMd5({imgMd5: imageMd5})
        this.imgUrl = data?.verify?.img_url
        if (!!data?.verify?.result) {


          //需要上传
          //0.判断是否已经初始化阿里云client


          if (!!!this.ossClient) {






            //1.获取阿里云配置
            const {data} = await ossStsConfig()
            const {AccessKeyId, AccessKeySecret, SecurityToken} = data.Credentials

            //2.初始化阿里云client
            this.ossClient = new OSS({
              region: 'oss-cn-shenzhen',
              accessKeyId: AccessKeyId,
              accessKeySecret: AccessKeySecret,
              stsToken: SecurityToken,
              bucket: process.env.VUE_APP_OSS_BUCKET_NAME,
              refreshSTSToken: async () => {
                const {data} = await ossStsConfig()
                return {
                  accessKeyId: data.AccessKeyId,
                  accessKeySecret: data.AccessKeySecret,
                  stsToken: data.SecurityToken,
                }
              }
            })

          }


          //3.上传到阿里云oss
          let userInfoID = this.userInfo.merchant[0].id
          try {
            await this.ossClient.multipartUpload(
                baseUrl+ userInfoID + '/' + imageMd5 + name.substring(name.lastIndexOf('.')),
                file,
                {
                  progress: p => {
                    //判断当前到uuid是谁到，更新其进度条
                    this.uploadFileList.forEach(e => {
                      if (e.uuid === uuid) {
                        e.progress = p
                      }
                    })
                  }
                })

          } catch (e) {
            console.log(e)
          }


          let imageUrl = baseUrl + userInfoID + '/' +  imageMd5 + name.substring(name.lastIndexOf('.'))

          const url = baseHttpPrefix + baseUrl+ userInfoID + '/'  + imageMd5 + name.substring(name.lastIndexOf('.'))


          //判断图片是否有效

          const {data:type} = await getImgFormatInfo({
            imgUrl:url,
          })
          let mimeType = type?.mimeType

          if(mimeType !== 'image/jpeg' && mimeType !== 'image/jpg' && mimeType !== 'image/png' ) {

            await this.ossClient.delete(imageUrl)
            this.$message.error('上传失败：仅支持jpeg/jpg/png')
            this.uploadFileList.forEach(e => {
              if (e.uuid === uuid) {
                e.error = true
              }
            })
            return false
          }

          //4.提交信息保存图片
          console.log(imageUrl)
          const params = {
            name,
            md5: imageMd5,
            larger: imageUrl,
            albumId: this.form.region
          }

          params.albumId = this.uploadFileList.find(e => {
            if (e.uuid === uuid) {
              return e
            }
          })?.photoAlbumId

          await saveImage(params)


          // const url = baseHttpPrefix + this.imgUrl
          this.uploadFileList.forEach(e => {
            if (e.uuid === uuid) {
              e.error = false
              e.url = url
              e.progress = 0
            }
          })

        } else {
          //不需要上传 提交信息保存图片
          const params = {
            name,
            md5: imageMd5,
            // larger: baseUrl + imageMd5 + name.substring(name.lastIndexOf('.'))
            larger: this.imgUrl.larger || this.imgUrl ,
            albumId: this.form.region
          }
          params.albumId = this.uploadFileList.find(e => {
            if (e.uuid === uuid) {
              return e
            }
          })?.photoAlbumId
          await saveImage(params)
          const url = baseHttpPrefix + this.imgUrl
          this.uploadFileList.forEach(e => {
            if (e.uuid === uuid) {
              e.error = false
              e.url = url
              e.progress = 0
            }
          })
        }
        const url = baseHttpPrefix + baseUrl+ this.userInfo.merchant[0].id + '/'  + imageMd5 + name.substring(name.lastIndexOf('.'))
        // const url = baseHttpPrefix + this.imgUrl
        this.uploadFileList.forEach(e => {
          if (e.uuid === uuid) {
            e.error = false
            e.url = url
            e.progress = 0
          }
        })
      } catch (e) {
        this.uploadFileList.forEach(e => {
          if (e.uuid === uuid) {
            e.error = true
            e.url = null
            e.progress = 0
          }
        })

        // let {msg} = e
        // if (msg === 'album id 必须是一个数字。') {
        //   this.$message.error('请选择图册')
        // } else {
        this.$message.error(e)
        // }
      }
    },


    // 创建上传图片
    createImage(uuid) {
      this.uploadFileList.push({
        uuid,
        progress: 0,
        url: null,
        error: false,
        photoAlbumId: this.form.region || undefined
      })
    },

    //转化base64
    async fileByBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          // target.result 该属性表示目标对象的DataURL
          resolve(e.target.result)
        };
      })
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
}
</script>
