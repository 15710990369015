<template>
  <div>
    <!--    列表-->
    <el-row class="title" v-if="!switchActiveShow || atlasShow">
      <el-col :xs="8" :sm="16" :md="14" :lg="10" :xl="10" style="padding-left: 50px;">
        名称
      </el-col>
      <el-col :xs="8" :sm="5" :md="5" :lg="10" :xl="10">大小</el-col>
      <el-col :xs="8" :sm="3" :md="5" :lg="4" :xl="4">操作</el-col>
    </el-row>


    <div v-if="atlasShow">
      <el-row v-for="item in categoryList" :key="item.id" class="content_col cup" @click.native="getPictureId(item.id)">
        <el-col :xs="8" :sm="16" :md="14" :lg="10" :xl="10" class="col_flex">
          <div class="flex" style="padding-left: 30px;">
            <!--          <el-checkbox-group v-model="checkList" class="el_check"  >-->
            <!--            <el-checkbox :label="item.id" ></el-checkbox>-->
            <!--          </el-checkbox-group>-->
            <el-image :src="moren" style="width: 30px;height: 30px;"></el-image>
          </div>
          <div class="name">{{ item.name }}</div>
        </el-col>
        <el-col :xs="8" :sm="5" :md="5" :lg="10" :xl="10">--</el-col>
        <el-col :xs="8" :sm="3" :md="5" :lg="4" :xl="4" class="download" @click.native.stop v-if="item.id !== 0">
          <el-popover
              placement="bottom"
              width='95'
              trigger="click"
              popper-class='elPopover'>
            <i class="el-icon-more" slot="reference"></i>
            <div class="operation">
              <div @click="modifyName(item.id)">修改名称</div>
              <!--            <div @click="classification(item.id)">分类</div>-->
              <!--            <div @click="download(item.img,item.name)">下载</div>-->
              <div @click="removerAtlas(item.id)">删除</div>
            </div>
          </el-popover>
        </el-col>
      </el-row>
    </div>


    <div v-else>
      <div v-if="switchActiveShow">
        <el-row>
          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2"
                  v-for="(item,index) in imagesList"
                  :key="item.id"
                  class="img_box"
                  :class="{'active_bg':item.checkboxShow}"
                  v-infinite-scroll="load"
                  infinite-scroll-disabled="disabled"
                  :immediate-check="false"
          >

            <div class="icon_flex">
              <el-checkbox-group v-model="checkList" class="el_check" :class="{'active_bg':item.checkboxShow}">
                <el-checkbox :label="item.id" @change="checkboxChange(item.id)"></el-checkbox>
              </el-checkbox-group>
              <div style="width: 16px;height: 19px;"></div>
              <el-popover
                  placement="bottom"
                  width='95'
                  trigger="click"
                  popper-class='elPopover'>
                <i class="el-icon-more" slot="reference" :class="{'active_bg':item.checkboxShow}"></i>
                <div class="operation">
                  <div @click="changeName(item.id)">更改名称</div>
                  <div @click="classification(item.id)">分类</div>
                  <div @click="download(item.larger.img,item.name)">下载</div>
                  <!--                <a :href="item.img" :download="item.name" style="color: #606266;text-decoration: none">下载</a>-->
                  <div @click="remover(item.id)">删除</div>
                </div>
              </el-popover>

            </div>
            <div class="img_div">
              <el-image
                  :src="item.larger.img400"
                  style="width: 100%;height: 100%;"
                  fit='scale-down'
                  @click="showBigPicture(item)"
                  :preview-src-list="bigUrl"
                  title="查看预览图"
              ></el-image>
            </div>
            <!--              <div class="name">{{item.name }}.{{ item.format}}</div>-->
            <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom">
              <div class="name">{{ item.name }}</div>
            </el-tooltip>
            <div class="img_size">{{ item.size }}KB</div>
          </el-col>
        </el-row>
      </div>

      <div v-else>
        <el-row v-for="item in imagesList" :key="item.id" class="content_col"
                v-infinite-scroll="load"
                infinite-scroll-disabled="disabled"
                :immediate-check="false"
                infinite-scroll-distance="100px"
        >
          <el-col :xs="8" :sm="16" :md="14" :lg="10" :xl="10" class="col_flex">
            <div class="flex">
              <el-checkbox-group v-model="checkList" class="el_check">
                <el-checkbox :label="item.id"></el-checkbox>
              </el-checkbox-group>
              <el-image :src="item.larger.img400" style="width: 30px;height: 30px;" fit='scale-down'></el-image>
            </div>
            <div class="name">{{ item.name }}</div>
          </el-col>
          <el-col :xs="8" :sm="5" :md="5" :lg="10" :xl="10">{{ item.size }}KB</el-col>
          <el-col :xs="8" :sm="3" :md="5" :lg="4" :xl="4" class="download">
            <el-popover
                placement="bottom"
                width='95'
                trigger="click"
                popper-class='elPopover'>
              <i class="el-icon-more" slot="reference" :class="{'active_bg':item.checkboxShow}"></i>
              <div class="operation">
                <div @click="changeName(item.id)">更改名称</div>
                <div @click="classification(item.id)">分类</div>
                <div @click="download(item.larger.img,item.name)">下载</div>
                <!--              <a :href="item.img" :download="item.name" style="color: #606266;text-decoration: none">下载</a>-->
                <div @click="remover(item.id)">删除</div>
              </div>
            </el-popover>
          </el-col>
        </el-row>
      </div>

    </div>
    <!--    数据加载提示-->
    <p class="loading" v-if="loading">
      <span class="el-icon-loading"></span>
      <span>加载中...</span>
    </p>

    <add-album v-if="addAlbumShow" @addAlbum="addAlbum"/>

    <create-photo
        :title="title"
        :createPhotoShow="createPhotoShow"
        @comShow="comShow"
        :editorId="editorId"
        @create="create"
        @edit="edit"
        :categoryList="categoryList"
        :editorName="editorName"
        :brief="brief"
    />
  </div>
</template>

<script>
import moren from "@/assets/moren.png";
import {deleteAlbum, puteditAlbum, postaddAlbum} from '@/api/map'
import CreatePhoto from "./createPhoto";
import addAlbum from "./addAlbum";

export default {
  components: {CreatePhoto, addAlbum},
  props: {
    imagesList: {
      type: Array,
      default: () => ([])
    },
    categoryList: {
      type: Array,
      default: () => ([])
    },
    noMore: {
      type: Boolean,
      default: false
    },
    switchActiveShow: {
      type: Boolean,
      default: false
    },

  },
  name: "galleryList",
  data: () => ({
    checkList: [],//复选框
    moren,
    title: '编辑相册',
    createPhotoShow: false,
    editorId: null,//
    editorName: null,
    brief: null,
    atlasShow: true,
    bigUrl: null,    //大图地址
    loading: false,
    setLoading: true,
    addAlbumShow: false
  }),
  mounted() {

  },
  methods: {
    showBigPicture(item) {
      // console.log(item)
      // this.bigShow = true
      this.bigUrl = item.larger.img
      let arr = []
      arr.push( item.larger.img)
      this.bigUrl = arr
    },
    load() {
      // console.log('启动滚动')
      // this.loading = true
      // setTimeout(() => {
      this.$emit('dataLoading')
      // this.loading = false
      // }, 100)
    },
    loadingFalse() {
      this.loading = false
    },
    setAtlasShow() {
      console.log('@@')
      this.atlasShow = true
      this.loading = false
    },
    setatlasShowFlase() {
      console.log('@@')
      this.atlasShow = false
      this.loading = false

    },
    addAlbum() {
      this.createPhotoShow = true
      this.title = '新建相册'
    },
    getPictureId(id) {
      this.$emit('setPictureId', id)
      this.atlasShow = false
    },
    //编辑弹窗
    async edit(val) {
      try {
        const {message} = await puteditAlbum(
            {
              albumId: this.editorId,
              name: val.name,
              brief: val.describe
            }
        )
        this.$emit('update')
        this.$message.success(message)
      } catch (e) {
        this.$message.error(e)
      }
    },
    //新增弹窗
    async create(val) {
      try {
        const {message} = await postaddAlbum(
            {
              name: val.name,
              brief: val.describe
            }
        )
        this.$emit('update')
        this.$message.success(message)
      } catch (error) {
        this.$message.error(error)
      }
    },
    //关闭弹窗
    comShow() {
      this.createPhotoShow = false
    },
    //修改相册名称
    modifyName(id) {
      this.editorId = id
      this.createPhotoShow = true
      this.categoryList.forEach(item => {
        if (item.id === id) {
          this.editorName = item.name
          this.brief = item.brief
        }
      })
    },
    //删除图册
    removerAtlas(id) {

      this.$confirm('此操作将永久删除该图册, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteAlbum({albumId: id})
        this.$emit('update')
        this.$message({
          type: 'success',
          message: '删除成功!'
        });

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });


    },
    //更改名称
    changeName(id) {
      this.$emit('show', id)

    },
    //分类
    classification(id) {
      // console.log(id);
      this.$emit('classifyIsShow', id)
    },
    // 删除图片
    remover(id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('remover', id)


      }).catch((e) => {
        console.log(e)
      })
    },
    // 下载图片
    download(imgsrc, name) {
      let a = document.createElement('a');          // 创建一个a节点插入的document
      let event = new MouseEvent('click')              // 模拟鼠标click点击事件
      a.download = name                                     // 设置a节点的download属性值
      a.href = imgsrc;                                      // 将图片的src赋值给a节点的href
      a.dispatchEvent(event)                                // 触发鼠标点击事件
    },
    checkEmptyList() {

      this.checkList = []
    },
    // 全选
    checkedTrueList() {
      let arr = []
      this.imagesList.forEach(item => {
        arr.push(item.id)
        item.checkboxShow = true
      })
      this.checkList = arr

    },
    checkboxChange(id) {
      this.imagesList.forEach(item => {
        if (!!item.checkboxShow) {
          if (item.id === id) {
            item.checkboxShow = false
          }
        } else {
          if (item.id === id) {
            item.checkboxShow = true
          }
        }
      })
    },
    // 未全选
    checkedFalseList() {
      this.checkList = []
      this.imagesList.forEach(item => {

        item.checkboxShow = false
      })
    },
  },
  watch: {
    checkList() {

      let arr = []
      this.imagesList.forEach(item => {
        arr.push(item.id)
      })
      //全选
      if (this.checkList.length > 0 && arr.length === this.checkList.length) {
        this.$emit('allCheck')
        //选中一个或多个且未选择全部
      } else if (this.checkList.length > 0 && arr.length > this.checkList.length) {
        this.$emit('updateCheckedFlase')
      } else {
        // 未选择一个
        this.$emit('isIndeterminateFlase')
      }
      this.$emit('getCheckListID', this.checkList)


    },
    categoryList: {
      handler() {
        if (!this.categoryList.length && this.atlasShow) {
          this.addAlbumShow = true
        }
      }
    },

  },
  computed: {
    disabled() {
      return this.loading || this.noMore
    },
    // nameFormat(){
    //   return (name,format)=>{
    //     let arr =  name.split('.')
    //     let flag = arr.some(item=> item === format )
    //     return    !!flag ? name : name + '.' + format
    //   }
    // }
  }

}
</script>

<style scoped lang="less">
.loading {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;

  .el-icon-loading {
    margin-right: 10px;
  }
}


.img_box {
  height: 186px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;

  &:hover {
    background: rgba(255, 126, 86, 0.05);
  }

  &:hover .el-icon-more {
    display: block;
  }

  &:hover .el_check {
    display: block;
  }

  .icon_flex {
    display: flex;
    justify-content: space-between;

  }

  .img_div {
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px auto 20px;
    cursor: pointer;
  }

  .name {
    font-size: 13px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* 这里是超出几行省略 */
    overflow: hidden;

  }

  .img_size {
    font-size: 13px;
    color: #999999;
    line-height: 25px;
  }

  .el-icon-more {
    color: #FA4A14;
    cursor: pointer;
    font-weight: 600;
    display: none;
  }

  .el_check {
    display: none;
  }
}

/deep/ .el-checkbox__label {
  display: none;
}

.active_bg {
  background: rgba(255, 126, 86, 0.05) !important;
  display: block !important;
}

.loading {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;

  .el-icon-loading {
    margin-right: 10px;
  }
}

/deep/ .el-checkbox__label {
  display: none;
}

.flex {
  display: flex;
}

.title {
  height: 50px;
  background: #F7F7F7;
  line-height: 50px;
  font-size: 15px;
  font-weight: 600;
  color: #333333;

}

/deep/ .el-col {
  border: 1px solid transparent;
}

.cup {
  cursor: pointer;
}

.content_col {
  padding: 15px 0 15px 20px;
  font-size: 14px;
  border-bottom: 1px solid #E9E9E9;
  line-height: 35px;

  .col_flex {
    display: flex;

    .el_check {
      line-height: 30px;
      margin-right: 20px;
    }
  }

  .name {
    margin-left: 10px;
    line-height: 30px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* 这里是超出几行省略 */
    overflow: hidden;
  }

  .download {
    color: #FA4A14;
    cursor: pointer;

  }
}

.operation {
  cursor: pointer;

  & > div:hover {
    // width: 120px;
    height: 20px;
    background: #eeeeee;
  }
}
</style>