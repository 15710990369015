<template>
  <div class="gallery-foot">
   <el-pagination
     background
     layout="prev, pager, next"
     :page-size="parseInt(meta.per_page)"
     :total="meta.total"
      @current-change="handleCurrentChange"
        prev-text='上一页'
        next-text='下一页'
     :current-page="parseInt(meta.current_page)"
     :hide-on-single-page="true"
     >
      
   </el-pagination>
  </div>
</template>

<script>
export default {
  props:{
    meta:{
      type:Object,
      default:() =>{}
    }
  
  },
  data () {
    return {
      
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    handleCurrentChange(currentPage){
      //  this.meta.current_page = currentPage;
       this.$emit('currentPage',currentPage)
   
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-pagination {
  margin-left: 40%;
}
/deep/.btn-prev ,
/deep/.btn-next{
  padding: 0 10px !important;
}
/deep/.el-pagination__total {
  font-size: 14px !important;
  font-weight: 400;
  color: #333333;
  margin-left: 5px;
}
</style>
