<template>
  <div class="sort-compile">    
  <el-dialog
  :title="title"
  :visible.sync="createPhotoShow"
  width="30%"
  :before-close="close"
  >
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px" class="demo-ruleForm" size="small">
   <el-form-item label="名称" prop="name">
    <el-input v-model="ruleForm.name"></el-input>
  </el-form-item>
   <el-form-item label="描述" prop="describe">
    <el-input type="textarea" v-model="ruleForm.describe"></el-input>
  </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="close">取 消</el-button>
    <el-button type="primary" @click="sure">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
export default {
 
   props:{
     // 弹窗 状态
       createPhotoShow:{
           type:Boolean,
           default:false
       },
     categoryList:{
         type: Array,
         default:()=>([])
       },
     title:{
         type:String,
     },
     editorName:{
         type:String,
         default:null
     },
     brief:{
       type:String,
       default:null
     },
     editorId:{
         type:Number,
         default:null
     }
   },
  data () {
    let validatePass = (rule, value, callback)=>{
      let arr = []
      this.categoryList.forEach(item=>{
        arr.push(item.name)
      })
      if(arr.indexOf(value) !== -1) {
        callback(new Error('名称已存在请重新输入'))
      } else {
        callback()
      }
      
    }
    return {
        ruleForm:{
            name:'',
            describe:''
        },
        rules:{
            name:[
              { required: true, message: '请输入名称', trigger: 'blur' },
              { validator: validatePass, trigger: 'blur' }
            ],
            describe:[{required: true, message: '请输入描述', trigger: 'blur'}]
        }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    // 关闭弹窗
      close(){
          this.$emit('comShow',false)
          this.$refs.ruleForm.resetFields()
          this.ruleForm = {
            name:'',
            describe:''
        }
      },
      //点击确定
      sure(){
        this.$refs.ruleForm.validate((val)=>{
          
               if(val){
                 if(!!this.editorId) {
                   this.$emit('edit',this.ruleForm)
                 } else {
                   this.$emit('create',this.ruleForm)
                 }

                    this.close()
             
               } else {
                 this.$message.error('请输入名称、描述')
               }
                   
            
        })
          
        
      }
  },
  watch:{
    editorName:{
      handler(){
        if(this.editorName) {
          this.ruleForm.name = this.editorName
          this.ruleForm.describe = this.brief
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-textarea__inner {
  resize: none;
}
</style>
