<template>
  <div>
    <div class="gallery-columns ">
      <!--          宫格-->
      <!--          v-loading="loading"-->
      <!--          element-loading-text="拼命加载中...."-->
      <!--          element-loading-spinner="el-icon-loading"-->
      <!--          element-loading-background="#fff"-->

      <el-row class="title" v-if="!switchActiveShow && !atlasShow">
        <el-col :xs="8" :sm="16" :md="14" :lg="10" :xl="10" style="padding-left: 50px;">
          名称
        </el-col>
        <el-col :xs="8" :sm="5" :md="5" :lg="10" :xl="10">大小</el-col>
        <el-col :xs="8" :sm="3" :md="5" :lg="4" :xl="4">操作</el-col>
      </el-row>

      <div v-if="atlasShow">
        <el-row>
          <el-col
              :xs="8"
              :sm="6"
              :md="4"
              :lg="3"
              :xl="2"
              v-for="(item,index) in categoryList"
              :key="item.id" class="img_box"
              :class="{'active_bg':item.checkboxShow}"

          >
            <div class="icon_flex">
              <!--                <el-checkbox-group v-model="checkList" class="el_check" :class="{'active_bg':item.checkboxShow}"  >-->
              <!--                  <el-checkbox :label="item.id" @change="checkboxChange(item.id)"></el-checkbox>-->
              <!--                </el-checkbox-group>-->
              <div style="width: 16px;height: 19px;"></div>
              <el-popover
                  v-if="item.id !== 0"
                  placement="bottom"
                  width='95'
                  trigger="click"
                  popper-class='elPopover'>
                <i class="el-icon-more" slot="reference" :class="{'active_bg':item.checkboxShow}"></i>
                <div class="operation">
                  <div @click="changeAtlasName(item.id)">更改名称</div>
                  <!--                    <div @click="classification(item.id)">分类</div>-->
                  <!--                    <div @click="download(item.img,item.name)">下载</div>-->
                  <div @click="removerAtlas(item.id)">删除</div>
                </div>
              </el-popover>

            </div>
            <div class="img_div">
              <el-image
                  :src="moren"
                  style="width: 100%;height: 100%;"
                  fit='fill'
                  @click.native="getPictureId(item.id)"
              ></el-image>
            </div>
            <div class="name">{{ item.name }}</div>
            <!--              <div class="img_size">&#45;&#45;</div>-->
          </el-col>
        </el-row>
      </div>

      <div v-else>
        <div v-if="switchActiveShow">
          <el-row>
            <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2"
                    v-for="(item,index) in imagesList"
                    :key="item.id"
                    class="img_box"
                    :class="{'active_bg':item.checkboxShow}"
                    v-infinite-scroll="load"
                    infinite-scroll-disabled="disabled"
                    :immediate-check="false"
            >

              <div class="icon_flex">
                <el-checkbox-group v-model="checkList" class="el_check" :class="{'active_bg':item.checkboxShow}">
                  <el-checkbox :label="item.id" @change="checkboxChange(item.id)"></el-checkbox>
                </el-checkbox-group>
                <div style="width: 16px;height: 19px;"></div>
                <el-popover
                    placement="bottom"
                    width='95'
                    trigger="click"
                    popper-class='elPopover'>
                  <i class="el-icon-more" slot="reference" :class="{'active_bg':item.checkboxShow}"></i>
                  <div class="operation">
                    <div @click="changeName(item.id)">更改名称</div>
                    <div @click="classification(item.id)">分类</div>
                    <div @click="download(item.larger.img,item.name)">下载</div>
                    <div @click="remover(item.id)">删除</div>
                  </div>
                </el-popover>

              </div>
              <div class="img_div">
                <el-image
                    :src="item.larger.img400"
                    style="width: 100%;height: 100%;"
                    fit='scale-down'
                    @click="showBigPicture(item)"
                    :preview-src-list="bigUrl"
                    title="查看预览图"
                ></el-image>
              </div>
              <!--              <div class="name">{{item.name }}.{{ item.format}}</div>-->
              <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom">
                <div class="name">{{ item.name }}</div>
              </el-tooltip>
              <div class="img_size">{{ item.size }}KB</div>
            </el-col>
          </el-row>
        </div>


        <!--              //宫格表格-->
        <div v-else>
          <el-row v-for="item in imagesList" :key="item.id" class="content_col"
                  v-infinite-scroll="load"
                  infinite-scroll-disabled="disabled"
                  :immediate-check="false"
          >
            <el-col :xs="8" :sm="16" :md="14" :lg="10" :xl="10" class="col_flex">
              <div class="flex">
                <el-checkbox-group v-model="checkList" class="el_check">
                  <el-checkbox :label="item.id"></el-checkbox>
                </el-checkbox-group>
                <el-image :src="item.larger.img400 " style="width: 30px;height: 30px;" fit='scale-down'></el-image>
              </div>
              <div class="name">{{ item.name }}</div>
            </el-col>
            <el-col :xs="8" :sm="5" :md="5" :lg="10" :xl="10">{{ item.size }}KB</el-col>
            <el-col :xs="8" :sm="3" :md="5" :lg="4" :xl="4" class="download">
              <el-popover
                  placement="bottom"
                  width='95'
                  trigger="click"
                  popper-class='elPopover'>
                <i class="el-icon-more" slot="reference" :class="{'active_bg':item.checkboxShow}"></i>
                <div class="operation">
                  <div @click="changeName(item.id)">更改名称</div>
                  <div @click="classification(item.id)">分类</div>
                  <div @click="download(item.larger.img,item.name)">下载</div>
                  <div @click="remover(item.id)">删除</div>
                </div>
              </el-popover>
            </el-col>
          </el-row>
        </div>
      </div>


      <p class="loading" v-if="loading">
        <span class="el-icon-loading"></span>
        <span>加载中...</span>
      </p>

    </div>
    <create-photo
        :title="title"
        :createPhotoShow="createPhotoShow"
        @comShow="comShow"
        @create="create"
        :categoryList="categoryList"
        :editorName="editorName"
        :brief="brief"
    />
  </div>
</template>

<script>
import {getDateDiff} from '@/utils/timejs.js';
import moren from "@/assets/moren.png";
import createPhoto from "./createPhoto";
import {puteditAlbum, deleteAlbum} from "@/api/map";
import axios from "axios";


export default {
  name: 'my-gallery-columns',
  components: {createPhoto},
  props: {
    changeNameshow: {
      type: Boolean
    },
    imagesList: {
      type: Array,
      required: false
    },
    categoryList: {
      type: Array,
      default: () => ([])
    },
    noMore: {
      type: Boolean,
      default: false
    },
    switchActiveShow: {
      type: Boolean,
      default: false
    },


  },
  data() {
    return {
      moren,

      checkList: [],//复选框
      loading: false,
      bigUrl: null,    //大图地址
      title: '编辑相册',
      createPhotoShow: false,
      editorId: null,
      editorName: null,
      brief: null,
      atlasShow: true,

    }
  },
  created() {

  },
  mounted() {


  },
  methods: {
    load() {
      // this.loading = true
      // setTimeout(() => {
      console.log('启动滚动2')
      this.$emit('dataLoading')
      //   this.loading = false
      // }, 1000)
    },

    getPictureId(id) {
      this.$emit('setPictureId', id)
      this.atlasShow = false
    },
    setAtlasShow() {
      this.loading = false
      this.atlasShow = true
    },
    setatlasShowFlase() {
      this.loading = false
      this.atlasShow = false
    },

    //删除图册
    removerAtlas(id) {
      try {
        this.$confirm('此操作将永久删除该图册, 是否继续?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await deleteAlbum({albumId: id})
          this.$emit('update')
          this.$message({
            type: 'success',
            message: '删除成功!'
          });

        })

      } catch (e) {
        console.log(e);
      }
    },
    //编辑弹窗
    async create(val) {
      try {
        const {message} = await puteditAlbum(
            {
              albumId: this.editorId,
              name: val.name,
              brief: val.describe
            }
        )
        this.$emit('update')
        this.$message.success(message)
      } catch (e) {
        this.$message.error(e)
      }
    },
    comShow() {
      this.createPhotoShow = false
    },
    changeAtlasName(id) {
      this.editorId = id
      this.createPhotoShow = true
      this.categoryList.forEach(item => {
        if (item.id === id) {
          this.editorName = item.name
          this.brief = item.brief
        }
      })
    },
    checkboxChange(id) {
      this.imagesList.forEach(item => {
        if (!!item.checkboxShow) {
          if (item.id === id) {
            item.checkboxShow = false
          }
        } else {
          if (item.id === id) {
            item.checkboxShow = true
          }
        }
      })
    },

    //下载图片
    download(imgSrc, name) {
      let a = document.createElement('a');          // 创建一个a节点插入的document
      let event = new MouseEvent('click')              // 模拟鼠标click点击事件
      a.download = name                                     // 设置a节点的download属性值
      a.href = imgSrc;                                      // 将图片的src赋值给a节点的href
      a.dispatchEvent(event)                                // 触发鼠标点击事件

      // let x = new XMLHttpRequest();
      // x.open("GET", imgSrc, true);
      // x.responseType = 'blob';
      // x.onload=function(e) {
      //   let url = window.URL.createObjectURL(x.response)
      //   let a = document.createElement('a');
      //   a.href = url
      //   a.download = name;
      //   a.click()
      // }
      // x.send();
    },


    showOperation() {
      this.OperationShow = !this.OperationShow
    },
    // 删除
    remover(id) {
      this.$confirm('此操作将永久删除该图片, 是否继续?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('remover', id)
      })
    },
    //更改名称
    changeName(id) {
      this.$emit('show', id)

    },
    //分类
    classification(id) {
      console.log(id);
      this.$emit('classifyIsShow', id)
    },
    checkEmptyList() {

      this.checkList = []
    },

    // 全选
    checkedTrueList() {
      let arr = []
      this.imagesList.forEach(item => {
        arr.push(item.id)
        item.checkboxShow = true
      })
      this.checkList = arr

    },
    // 未全选
    checkedFalseList() {
      this.checkList = []
      this.imagesList.forEach(item => {

        item.checkboxShow = false
      })
    },
    //大图展示
    showBigPicture(item) {
      // console.log(item)
      // this.bigShow = true
      this.bigUrl = item.larger.img
      let arr = []
      arr.push(item.larger.img)
      this.bigUrl = arr
    },
  },
  watch: {
    checkList() {

      let arr = []
      this.imagesList.forEach(item => {
        arr.push(item.id)
      })
      //全选
      if (this.checkList.length > 0 && arr.length === this.checkList.length) {
        this.$emit('allCheck')
        //选中一个或多个且未选择全部
      } else if (this.checkList.length > 0 && arr.length > this.checkList.length) {
        this.$emit('updateCheckedFlase')
      } else {
        // 未选择一个
        this.$emit('isIndeterminateFlase')
      }
      this.$emit('getCheckListID', this.checkList)


    },

  },
  computed: {

    disabled() {
      return this.loading || this.noMore
    },
    time() {
      return function (time) {
        return getDateDiff(time)
      }

    },
    alias() {
      return (item) => {
        return item?.user?.alias

      }
    },
    // nameFormat(){
    //     return (name,format)=>{
    //      let arr =  name.split('.')
    //       let flag = arr.some(item=> item === format )
    //       return    !!flag ? name : name + '.' + format
    //     }
    // }
  }
}
</script>

<style scoped lang="less">

.loading {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;

  .el-icon-loading {
    margin-right: 10px;
  }
}


.img_box {
  height: 186px;
  border-radius: 5px;
  text-align: center;
  padding: 10px;

  &:hover {
    background: rgba(255, 126, 86, 0.05);
  }

  &:hover .el-icon-more {
    display: block;
  }

  &:hover .el_check {
    display: block;
  }

  .icon_flex {
    display: flex;
    justify-content: space-between;

  }

  .img_div {
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px auto 20px;
    cursor: pointer;
  }

  .name {
    font-size: 13px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* 这里是超出几行省略 */
    overflow: hidden;

  }

  .img_size {
    font-size: 13px;
    color: #999999;
    line-height: 25px;
  }

  .el-icon-more {
    color: #FA4A14;
    cursor: pointer;
    font-weight: 600;
    display: none;
  }

  .el_check {
    display: none;
  }
}

/deep/ .el-checkbox__label {
  display: none;
}

.active_bg {
  background: rgba(255, 126, 86, 0.05) !important;
  display: block !important;
}

.loading {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;

  .el-icon-loading {
    margin-right: 10px;
  }
}

/deep/ .el-checkbox__label {
  display: none;
}

.flex {
  display: flex;
}

.title {
  height: 50px;
  background: #F7F7F7;
  line-height: 50px;
  font-size: 15px;
  font-weight: 600;
  color: #333333;

}

/deep/ .el-col {
  border: 1px solid transparent;
}

.cup {
  cursor: pointer;
}

.content_col {
  padding: 15px 0 15px 20px;
  font-size: 14px;
  border-bottom: 1px solid #E9E9E9;
  line-height: 35px;

  .col_flex {
    display: flex;

    .el_check {
      line-height: 30px;
      margin-right: 20px;
    }
  }

  .name {
    margin-left: 10px;
    line-height: 30px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* 这里是超出几行省略 */
    overflow: hidden;
  }

  .download {
    color: #FA4A14;
    cursor: pointer;

  }
}

.operation {
  cursor: pointer;

  & > div:hover {
    // width: 120px;
    height: 20px;
    background: #eeeeee;
  }
}
</style>
