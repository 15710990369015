<template>
  <div class="Albumbox">
      <div class="addAlbum">
          <div class="image">
              <img src="~@/assets/icon/album.png" alt="">
          </div>
          <div class="text">暂时还没有相册，快来添加吧！</div>
        <button class="addStore" @click="addAlbum"><i class="el-icon-circle-plus-outline"></i> 新建相册</button>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
      addAlbum(){
          this.$emit('addAlbum',true)
      }
  }
}
</script>

<style scoped lang="less">
.Albumbox {
  position: relative;
  width: 100%;
  height: 314px;
}
.addAlbum {
  width: 200px;
  height: 200px;
  position: absolute;
  left: 40%;
  top: 30%;

  .image {
      width: 90px;
      height: 90px;
      margin-left: 40%;
      transform: translateX(-50%);
  }
  .text {
        font-size: 14px;
        font-weight: 500;
        color: #999999;
        line-height: 20px;
        margin-top: 10px;
  }
   .addStore {
        width: 150px;
        height: 40px;
        background: #FA4A14;
        box-shadow: 0px 0px 10px 0px #F93A00;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 25px;
        margin-top: 50px;
        margin-left: 20px;
        text-align: center;
        .el-icon-circle-plus-outline {
            font-size: 20px;
           
        }
    }
}

</style>
