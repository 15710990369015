<template>
<div>
  <div class="gallery-top">
    <div class="left">
      <button @click="uploadImg" class="design-btn upload_btn"><i class="el-icon-circle-plus-outline" ></i>上传图片</button>
      <button @click="newAlbum" class="design-btn add_img"><i class="el-icon-folder-add" ></i>新建相册</button>

    </div>
    <div class="right">
      <div v-if="imagesList.length">
        共<span class="sum">{{!!meta.total?meta.total:0}}</span>个

      </div>
    </div>

  </div>
  <create-photo
      :createPhotoShow="createPhotoShow"
      @comShow="comShow"
      :categoryList="categoryList"
      @create="create"
      :title="title"
  />
</div>
</template>

<script>
import {  postaddAlbum } from '@/api/map.js';
import {mapMutations} from 'vuex'
import CreatePhoto from "./createPhoto";
export default {
  name:'my-gallery-top',
  components: {CreatePhoto},
  props:{
     meta:{
          type:Object,
          required:false
      },
    categoryList:{
       type:Array,
      default:()=>([])
    },
    imagesList:{
      type:Array,
      default:()=>([])
    },
  },
  data () {
    return {

        createPhotoShow:false,
        title:'创建相册'
    }
  },
  created () {
  },
  mounted () {

  },
  methods: {
    async create(val){
      try {
        const { message } = await postaddAlbum({name:val.name,brief:val.describe})
        this.$emit('update')
        this.$message.success(message)
      } catch (error) {
        this.$message.error(e)
      }
    },
    //新建相册的显示
    newAlbum(){
      this.createPhotoShow = true
    },
    //新建相册不显示
    comShow(){
      this.createPhotoShow = false
    },
    ...mapMutations([
      'designer/steShowUploadPop',
    ]),


    // 上传图片弹窗
      uploadImg(){
      this.$emit('changeUploadImg')

    },

  }
}
</script>

<style scoped lang="less">

.gallery-top{
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;
    .text{
        color: #666666;
    }

    .classify{

        display: flex;
        flex-wrap: wrap;
    }
    .date{
        display: flex;
    }
  .right{
        margin-right:30px;
        margin-left: 30px;
        text-align: center;
        .sum{
            font-size: 28px;
            padding: 0 5px;
            color:#FA4A14;  
        }
    }
}
  .el-dropdown-link {
    cursor: pointer;
    color:#FA4A14;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }

  .left {
    position: relative;
    width: 750px;
    display: flex;
    .upload_btn {
      background: #FA4A14;
    }
    .add_img {
      border: 1px solid #FA4A14 !important;
      color: #FA4A14 !important;
    }
    .design-btn{
      width: 128px;
      height: 40px;
      border-radius: 5px;
      border: 0px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      font-size: 16px;
      margin-right: 10px;
      margin-bottom: 20px;
      /deep/.el-icon-circle-plus-outline,.el-icon-folder-add{
        font-size: 26px;
        margin-right:5px ;
      }
    }
  }
.maskShow {
  display: block;
}
</style>
