<template>
  <div>
   <!-- 分类 -->
<el-dialog
  title="图册"
  :visible.sync="classifyShow"
  width="654px"
  :before-close="handleClose">
    <el-form label-width="90px">
        <el-form-item  >
          <div class="left30px">
                <el-select v-model="form.region" placeholder="选择分类">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
             
            </el-select>    
          </div>
        </el-form-item>
     </el-form>
       <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="determine">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { addToAlbum } from '@/api/map.js';
export default {
  props:{
    classifyShow:{
      type:Boolean,
      default:false
    },
    // 图册
     categoryList:{
        type:Array,
        required:false
      },
      //图册id
      addToAlbumId:{
        type:Number,
        default:null
      },
      // 批量选择ID
      checkListID:{
        type: Array,
        default: ()=>[]
      }
  },
  data () {
    return {
     form: {
          region: '',
        },
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    // 关闭弹窗
      handleClose(done) {
      this.$emit('close')
      this.form.region = ''
      },
      // 确定
      async determine(){
          try {
            const { message } = await addToAlbum({
                imgId:this.addToAlbumId || this.checkListID,
                albumId:this.form.region
            })
            this.$emit('updated')
            this.$emit('emptyId')
            this.handleClose()
            this.$message.success(message)
        
          } catch (error) {
            this.$message.error(error)
          }
           
      }
  }
}
</script>

<style scoped lang="less">
/deep/.el-dialog__header {
    border-bottom: 1px solid #E7E7E7;
}
/deep/.el-dialog__title {
    font-size: 16px;
    font-weight: 600;
    font-family: PingFang-SC-Medium, PingFang-SC;
    color: #333333;
    line-height: 22px;
}
/deep/.el-form-item__label {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
}
/deep/.el-button {
    font-size: 16px;
}
/deep/.el-dialog__title {
    width: 64px;
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
}
/deep/.el-dialog__body {
    padding: 10px 0;
}
/deep/.el-form-item__content {
    margin-left: 0 !important;
}
/deep/.el-input__inner {
    width: 590px;
    height: 26px;
}

/deep/.el-form-item {
    margin-bottom: 10px;
}
.left30px {
    margin-left: 32px;
}
</style>
